import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.toastr.error(err.error.message)

        // Swal.fire({
        //   icon: 'error',
        //   title: 'غير مسموح لك بالدخول',
        //   text: err.error.message,
        //   // denyButtonColor: '#F14437',
        //   showDenyButton: true,
        //   showConfirmButton: false,
        //   denyButtonText: 'الخروج من المنصة',
        //   heightAuto: false
        // })
        this.modalService.dismissAll()
        localStorage.removeItem('user');
        localStorage.removeItem('token')
        this.spinner.hide()
        this.router.navigate(["/login"]);
      }
      if (err.status === 500) {
        this.spinner.hide()
        this.toastr.error(err.error.message)
      }
      if (err.status === 400) {
        this.spinner.hide()
        localStorage.setItem('otp', err?.error?.data?.attempts);
        this.toastr.error(err.error.message)
      }
      if (err.status === 422) {
        this.spinner.hide()
        this.toastr.error(err.error.message)
      }
      if (err.status === 429) {
        let currentTime = moment();
        localStorage.setItem('time', currentTime.add(1, 'm').toDate().toString())
        this.spinner.hide()
        this.toastr.error(err.error.message)
      }
      if (err.status === 403) {
        this.router.navigate(["/apps/home"]);
        this.spinner.hide()
        this.toastr.error(err.error.message)
        // this.modalService.dismissAll()
      }
      const error =  err;
      return throwError(error);
    }))
  }
}
