import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-table',
  templateUrl: './icon-table.component.html',
  styleUrls: ['./icon-table.component.scss']
})
export class IconTableComponent implements OnInit {

  constructor() { }
  @Input() matTooltipShow: string;
  @Input() icon: string;

  icon_show = ''
  icon_custom = ''
  ngOnInit() {

    switch (this.icon) {

      case 'edit':
        this.icon_show = '../../../assets/icons/table/edit.svg'
        break;

      case 'delete':
        this.icon_show = '../../../assets/icons/table/trash.svg'
        break;

      case 'camp':
        this.icon_show = '../../../assets/icons/table/camps-booked.svg'
        break;

      case 'add':
        this.icon_custom = 'fa fa-plus-square-o ml-1'
        break;

    }

  }

}
