import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements AfterViewInit {

  option: EChartsOption = {};
  @Input() chartData: any;
  @Input() title: any;
  constructor() { }
  ngAfterViewInit(): void {
    this.option = {

      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['المربعات المتبقية', 'المربعات المحجوزة']
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['النطاق 1', 'النطاق 2', 'النطاق 3', 'النطاق 4', 'النطاق 5']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'المربعات المتبقية',
          type: 'line',
          stack: 'Total',
                smooth: true,

          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          name: 'المربعات المحجوزة',
          type: 'line',
                      smooth: true,

          stack: 'Total',
          data: [220, 182, 191, 234, 290, 330, 310]
        },

      ]
    };  }



}
