export const map_base = [
  // 'https://api.tiles.mapbox.com/styles/v1/elhuseyni/cka1aqd9n4mx71iqdbcwwgaee/tiles/{tileSize}/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZWxodXNleW5pIiwiYSI6ImNrYTAxMjkydDBjMXUzZ3J0bnFmOWJla3cifQ.gxug-cM1XKuNEobUeZId4w',
  // 'https://api.mapbox.com/styles/v1/osmbuildings/cjt9gq35s09051fo7urho3m0f/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFzZXJqYW1iaSIsImEiOiJjbGk4bDR1azIxdDRtM2ZvOWI4bzhwcXFiIn0.oeW9BjX03MwrLz1cVjwyhw',
  `https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png`,
  // `https://api.mapbox.com/styles/v1/yaserjambi/clkb0nv9j00sb01pddjlqgd8g/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFzZXJqYW1iaSIsImEiOiJjbGk4bDR1azIxdDRtM2ZvOWI4bzhwcXFiIn0.oeW9BjX03MwrLz1cVjwyhw`,
  // 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  // `https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`
  // `https://api.mapbox.com/styles/v1/yaserjambi/clqzanrgm001w01o77cj8dj6u/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieWFzZXJqYW1iaSIsImEiOiJjbGk4bDR1azIxdDRtM2ZvOWI4bzhwcXFiIn0.oeW9BjX03MwrLz1cVjwyhw`,
  `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
];
