import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropAttachmenetComponent } from './drag-drop-attachmenet/drag-drop-attachmenet.component';
import { BtnSubComponent } from './buttons/btn-sub/btn-sub.component';
import { BtnPrimaryComponent } from './buttons/btn-primary/btn-primary.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TableComponent } from './table/table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchBoxComponent } from './search-box/search-box.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from './button/button.component';
import { NavigateBackComponent } from './navigate-back/navigate-back.component';
import { RouterModule } from '@angular/router';
import { CenteredSpinnerComponent } from './centered-spinner/centered-spinner.component';
import { FlagIconComponent } from './flag-icon/flag-icon.component';
import { IconTableComponent } from './icon-table/icon-table.component';
import { AlertTextComponent } from './alert-text/alert-text.component';
import { CampMapComponent } from './camp-map/camp-map.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { Pie1Component } from './charts/pie1/pie1.component';
import { Pie2Component } from './charts/pie2/pie2.component';
import { Pie3Component } from './charts/pie3/pie3.component';
import { PieRingComponent } from './charts/pie-ring/pie-ring.component';

import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { VBarChartComponent } from './charts/v-bar-chart/v-bar-chart.component';
import { GaugeChartComponent } from './charts/gauge-chart/gauge-chart.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { MultiBarComponent } from './charts/multi-bar/multi-bar.component';
import { StatisticCardComponent } from './statistic-card/statistic-card.component';
import { ThemeToggleComponent } from './theme-toggle/theme-toggle.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    DragDropAttachmenetComponent,
    BtnSubComponent,
    BtnPrimaryComponent,
    TableComponent,
    DatepickerComponent,
    SearchBoxComponent,
    PaginationComponent,
    ButtonComponent,
    NavigateBackComponent,
    CenteredSpinnerComponent,
    FlagIconComponent,
    IconTableComponent,
    AlertTextComponent,
    CampMapComponent,
    BarChartComponent,
    Pie1Component,
    Pie2Component,
    Pie3Component,
    PieRingComponent,
    LineChartComponent,
    VBarChartComponent,
    GaugeChartComponent,
    ProgressSpinnerComponent,
    MultiBarComponent,
    StatisticCardComponent,
    ThemeToggleComponent,
  ],
  imports: [
    CommonModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgbModule,
    RouterModule,
    MatProgressSpinnerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    FormsModule,
    MatIconModule,
  ],
  exports: [
    DragDropAttachmenetComponent,
    BtnSubComponent,
    BtnPrimaryComponent,
    TableComponent,
    DatepickerComponent,
    SearchBoxComponent,
    PaginationComponent,
    ButtonComponent,
    NavigateBackComponent,
    CenteredSpinnerComponent,
    FlagIconComponent,
    IconTableComponent,
    AlertTextComponent,
    CampMapComponent,
    BarChartComponent,
    Pie1Component,
    Pie2Component,
    Pie3Component,
    PieRingComponent,
    LineChartComponent,
    VBarChartComponent,
    GaugeChartComponent,
    ProgressSpinnerComponent,
    MultiBarComponent,
    StatisticCardComponent,
    ThemeToggleComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class SharedComponenetsModule {}
