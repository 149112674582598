import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
declare let tsParticles : any
@Component({
  selector: 'app-launching-page',
  templateUrl: './launching-page.component.html',
  styleUrls: ['./launching-page.component.scss']
})
export class LaunchingPageComponent implements OnInit {
  paused = true;
  pausable: any;

  ngOnInit() {
  
  }



}
