import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImpApiServicesService } from '../services/imp-api-services.service';
import { User } from '../constent/Route';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {



  constructor(
    private router: Router,
    private impApiServicesService: ImpApiServicesService,

  ) {


  }
  dataList = {
    navbar: null,
    title: null,
    subtitle: null,
    button: null,
  };
  ngOnInit(): void {
    this.impApiServicesService.get(User.cms.list).subscribe((data) => {
      this.dataList = {
        navbar: data.data.filter(d => d.type == 'navbar'),
        title: data.data.filter(d => d.type == 'title'),
        subtitle: data.data.filter(d => d.type == 'subtitle'),
        button: data.data.filter(d => d.type == 'button'),
      };
    }
    );



  }

  gotToPage(link) {
    window.open(link, '_blank');

  }


  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }



}
