import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { dataFile } from './data';

import jamarat from './geoData/jamarat.json';
import train_station_bounds from './geoData/train_station_bounds.json';
import trainstations_mashaeer from './geoData/trainstations_mashaeer.json';
import { map_base } from 'src/app/constent/map-base';

@Component({
  selector: 'app-camp-map',
  templateUrl: './camp-map.component.html',
  styleUrls: ['./camp-map.component.scss'],
})
export class CampMapComponent implements OnInit {
  // map
  myMap = null;
  basemapLayerLight: any;
  @Input() camp: any;
  @Input() height: string;
  @Input() width: string;
  constructor() {
    dataFile.map.jamaratLevelJSON = jamarat;
    dataFile.map.trainStationJSON = trainstations_mashaeer;
    dataFile.map.trainStationBoundaryJSON = train_station_bounds;
  }

  ngOnInit() {
    if (this.camp !== undefined) {
      this.startMap();
    }
  }

  startMap() {
    const lat = this.camp.geometry.coordinates[0][0][0][0];
    const lng = this.camp.geometry.coordinates[0][0][0][1];
    this.basemapLayerLight = L.tileLayer(
      map_base[1],
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        tileSize: 512,
        zoomOffset: -1,
        subdomains: ['mt1', 'mt2', 'mt3'],
      }
    );
    this.myMap = L.map('map', {
      center: [lng, lat],
      zoom: 17,
      maxZoom: 18,
      minZoom: 13,
      maxBoundsViscosity: 0.5,
      zoomControl: false,
      attributionControl: false,
      layers: this.basemapLayerLight,
    });

    L.control.scale().addTo(this.myMap);

    dataFile.myMap = this.myMap;

    dataFile.map.jamaratLevels = L.geoJson(dataFile.map.jamaratLevelJSON, {
      style: {
        color: 'black',
        fillColor: '#e67e22',
        fillOpacity: 0.6,
        weight: 0.5,
      },
    }).addTo(dataFile.myMap);

    dataFile.map.trainStationBoundaryLayer = L.geoJson(
      dataFile.map.trainStationBoundaryJSON,
      {
        style: {
          color: '#80533a',
          fillColor: '#d3b7ab',
          // fillColor: '#9fc7a7',
          fillOpacity: 0.8,
          weight: 2,
        },
      }
    ).addTo(dataFile.myMap);

    dataFile.map.trainStationLayer = L.geoJson(dataFile.map.trainStationJSON, {
      style: {
        color: '#d3b7ab',
        fillColor: '#80533a',
        // fillColor: '#43ca5f',
        fillOpacity: 0.8,
        weight: 2,
      },
    }).addTo(dataFile.myMap);

    var showLabel = (feature, layer) => {
      L.marker(layer.getBounds().getCenter(), {
        icon: L.divIcon({
          className: 'my-labels1',
          tooltipAnchor: [0, -35],
          html: feature.label,
          iconSize: [0, 0],
        }),
      }).addTo(dataFile.myMap);

      layer.on('mouseover', function (e) {
        layer.bindTooltip(feature.label, {
          direction: 'center',
          permanent: true,
          className: 'bindTooltip',
        });
      });

      layer.on('mouseout', function (e) {
        layer.unbindTooltip();
      });
    };

    var styleSelection = (features) => {
      return {
        color: '#039154',
        fillColor: '#039154',
        fillOpacity: 0.4,
        weight: 2,
      };
    };

    let data: any = {
      features: [this.camp],
      type: 'FeatureCollection',
    };

    data.features.forEach((element) => {
      element.type = 'Feature';
    });
    L.geoJson(data, {
      onEachFeature: showLabel.bind(this),
      style: styleSelection.bind(this),
    }).addTo(dataFile.myMap);
  }
}
