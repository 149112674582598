import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-multi-bar',
  templateUrl: './multi-bar.component.html',
  styleUrls: ['./multi-bar.component.scss'],
})
export class MultiBarComponent implements AfterViewInit {
  option: EChartsOption = {};
  @Input() chartData: any;
  @Input() title: any;
  @Input() Axis: any;
  @Input() legends: any;
  @Input() type: any;
  app: any = {};
  constructor() {}
  ngAfterViewInit(): void {
    const fully_empty = this.chartData;


    this.Axis = ['النطاق 1', 'النطاق 2', 'النطاق 3', 'النطاق 4', 'النطاق 5'];

    this.legends = [
      'الإجمالي',
      'محجوز بشكل كامل',
      'محجوز بشكل جزئي',
      'فارغ تماماً',
    ];
    const posList = [
      'left',
      'right',
      'top',
      'bottom',
      'inside',
      'insideTop',
      'insideLeft',
      'insideRight',
      'insideBottom',
      'insideTopLeft',
      'insideTopRight',
      'insideBottomLeft',
      'insideBottomRight',
    ] as const;

    this.app.configParameters = {
      rotate: {
        min: -90,
        max: 90,
      },
      align: {
        options: {
          left: 'left',
          center: 'center',
          right: 'right',
        },
      },
      verticalAlign: {
        options: {
          top: 'top',
          middle: 'middle',
          bottom: 'bottom',
        },
      },
      position: {
        options: posList.reduce(function (map, pos) {
          map[pos] = pos;
          return map;
        }, {} as Record<string, string>),
      },
      distance: {
        min: 0,
        max: 100,
      },
    };

    this.app.config = {
      rotate: 90,
      align: 'left',
      verticalAlign: 'middle',
      position: 'insideBottom',
      distance: 15,
      onChange: function () {
        const labelOption: BarLabelOption = {
          rotate: this.app.config.rotate as BarLabelOption['rotate'],
          align: this.app.config.align as BarLabelOption['align'],
          verticalAlign: this.app.config
            .verticalAlign as BarLabelOption['verticalAlign'],
          position: this.app.config.position as BarLabelOption['position'],
          distance: this.pp.config.distance as BarLabelOption['distance'],
        };
      },
    };

    type BarLabelOption = NonNullable<echarts.BarSeriesOption['label']>;

    const labelOption: BarLabelOption = {
      show: true,
      position: this.app.config.position as BarLabelOption['position'],
      distance: this.app.config.distance as BarLabelOption['distance'],
      align: this.app.config.align as BarLabelOption['align'],
      verticalAlign: this.app.config
        .verticalAlign as BarLabelOption['verticalAlign'],
      rotate: this.app.config.rotate as BarLabelOption['rotate'],
      formatter: '{c}',
      fontSize: 24,
      fontFamily: 'Janna LT,Roboto,Arial',
      rich: {
        name: {
          fontSize: 20,
        },
      },
    };

    this.option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: this.legends,
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: this.Axis,
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: this.legends[0],
          type: 'bar',
          barGap: 0,
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: fully_empty,
        },
        {
          name: this.legends[1],
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: [500, 182, 191, 234, 290],
        },
        {
          name: this.legends[2],
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: [150, 232, 201, 154, 190],
        },
        {
          name: this.legends[3],
          type: 'bar',
          label: labelOption,
          emphasis: {
            focus: 'series',
          },
          data: [98, 77, 101, 99, 40],
        },
      ],
    };
  }
}
