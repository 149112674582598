import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private darkThemeValue = new BehaviorSubject<boolean>(localStorage.getItem('DARK_THEME') === 'true');
  public darkTheme$ = this.darkThemeValue.asObservable();

  constructor() {
    this.darkThemeValue.next(localStorage.getItem('DARK_THEME') === 'true');
  }

  set darkTheme(isDarkTheme: boolean) {
    this.darkThemeValue.next(isDarkTheme);
    localStorage.setItem('DARK_THEME', String(isDarkTheme));
    this.updateTheme();
  }

  get darkTheme() {
    return this.darkThemeValue.getValue();
  }

  updateTheme() {
    if (this.darkThemeValue.getValue()) {
      document.body.setAttribute('data-bs-theme', 'dark');
    } else {
      document.body.setAttribute('data-bs-theme', 'light');
    }
  }
}
