import { Injectable } from '@angular/core';
import { map_base } from 'src/app/constent/map-base';
import * as L from 'leaflet';
import { NgxSpinnerService } from 'ngx-spinner';

import jamarat from '../helper/geoData/jamarat.json';
import train_station_bounds from '../helper/geoData/train_station_bounds.json';
import trainstations_mashaeer from '../helper/geoData/trainstations_mashaeer.json';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenerateMapService {

  mapToggle = false;
  myMap = null;
  basemapLayerLight: any;
  jamaratLevels = null
  trainStationBoundaryLayer = null
  trainStationLayer = null

  jamaratLevelsJson: any = jamarat;
  trainStationBoundaryLayerJson: any = train_station_bounds
  trainStationLayerJson: any = trainstations_mashaeer
  // Hybrid: s,h;
  // Satellite: s;
  // Streets: m;
  // Terrain: p;

  mapList = map_base
  mapBase = this.mapList[0];


  constructor(
    private spinner: NgxSpinnerService,

  ) {

  }


  start_map(data: any, index: any) {

    this.myMap = L.map(index, {
      center: [
        data?.shapes?.access_path?.coordinates[0][0][0][1] || data?.shapes?.actual_location?.coordinates[0][0][0][1],
        data?.shapes?.access_path?.coordinates[0][0][0][0] || data?.shapes?.actual_location?.coordinates[0][0][0][0]
      ],
      maxBoundsViscosity: 0.5,
      zoom: 15,
      zoomControl: false,
      attributionControl: false,
      layers: this.basemapLayerLight,
    });

    this.basemapLayerLight = L.tileLayer(this.mapBase, {
      maxNativeZoom: 15,
      maxZoom: 15,
      minZoom: 14,
    }).addTo(this.myMap);

    // L.control.scale().addTo(this.myMap);


    this.myMap = this.myMap;


    this.jamaratLevels = L.geoJson(this.jamaratLevelsJson, {
      style: {
        color: 'black',
        fillColor: '#e67e22',
        fillOpacity: 0.6,
        weight: 0.5,
      },
    }).addTo(this.myMap);

    this.trainStationBoundaryLayer = L.geoJson(
      this.trainStationBoundaryLayerJson,
      {
        style: {
          color: '#80533a',
          fillColor: '#d3b7ab',
          // fillColor: '#9fc7a7',
          fillOpacity: 0.8,
          weight: 2,
        },
      }
    ).addTo(this.myMap);

    this.trainStationLayer = L.geoJson(this.trainStationLayerJson, {
      style: {
        color: '#d3b7ab',
        fillColor: '#80533a',
        // fillColor: '#43ca5f',
        fillOpacity: 0.8,
        weight: 2,
      },
    }).addTo(this.myMap);


  }

  generate_Camp(data: any, index: any) {

    // camp
    let camp: any = {
      features: [data.shapes.actual_location],
      type: 'FeatureCollection',
    };
    L.control.layers({
      DarkGray: this.basemapLayerLight,
    });

    L.geoJson(camp, {
      style: {
        color: '#02673C',
        fillColor: '#039154',
        fillOpacity: 0.8,
        weight: 2,
      },
    }).addTo(this.myMap);


    // access
    let accessPath: any = {
      features: [data.shapes.access_path],
      type: 'FeatureCollection',
    };
    L.control.layers({
      DarkGray: this.basemapLayerLight,
    });

    L.geoJson(accessPath, {
      style: {
        color: "#2ecc71",
        opacity: 1,
        weight: 3,
      },
    }).addTo(this.myMap);


    // return
    let returnPath: any = {
      features: [data.shapes.return_path],
      type: 'FeatureCollection',
    };
    L.control.layers({
      DarkGray: this.basemapLayerLight,
    });

    L.geoJson(returnPath, {
      style: {
        color: "#e74c3c",
        dashArray: "20, 20",
        dashOffset: "0",
        weight: 3,
      },
    }).addTo(this.myMap);


  }

}
