import { helper } from './../../../constent/Route';
import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ChartServicesService } from '../chart-services.service';

@Component({
  selector: 'app-pie-ring',
  templateUrl: './pie-ring.component.html',
  styleUrls: ['./pie-ring.component.scss'],
})
export class PieRingComponent implements OnInit {
  option;
  @Input() title: any;
  @Input() value: any;
  @Input() parcent: any;
  @Input() color: any;

  constructor(private chartServicesService: ChartServicesService) {
    this.chartServicesService.getRefresh().subscribe(d => {
      this.ngAfterViewInit()

    })
  }

  ngOnInit() {
  }



  // refreshData() {
  //   this.title = this.title
  //   this.value = this.value
  //   this.parcent = this.parcent
  // }

  ngAfterViewInit(): void {

    if (this.title !== null && this.value !== null && this.parcent !== null) {
      const gaugeData = [

        {
          value: this.parcent,
          name: this.title,
          title: {
            offsetCenter: ['0%', '-10%']
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ['0%', '20%']
          }
        },

      ];

      this.option = {
        series: [
          {
            type: 'gauge',

            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                // borderColor: '#464646',
                color: this.color || '#ebcd94',

              }
            },
            axisLine: {
              lineStyle: {
                width: 15,
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: gaugeData,
            title: {
              fontSize: 25,
              fontFamily: 'Janna LT,Roboto,Arial'

            },
            detail: {
              show: true,
              width: 50,
              height: 14,
              fontSize: 19,
              color: 'inherit',
              // borderColor: 'inherit',
              // borderRadius: 20,
              // borderWidth: 1,
              formatter: `${this.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
              fontFamily: 'Janna LT,Roboto,Arial'
            }
          }
        ]
      };
    }


  }
}
