import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/constent/Route';

@Injectable({
  providedIn: 'root'
})
export class ImpAuthService {

  constructor(private http: HttpClient) { }


  login(data): Observable<any> {
    return this.http.post<any>((User.login), data);
  }

  verify(data) {
    return this.http.post<any>(User.verify, { otp: data });
  }


  logout(): Observable<any> {
    return this.http.post<any>((User.logout), null);
  }





}
