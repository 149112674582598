import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatMenuModule } from '@angular/material/menu';
import { ToastrModule } from 'ngx-toastr';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ErrorInterceptor } from './services/error.interceptor';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { TranslocoRootModule } from './transloco-root.module';
import { SharedComponenetsModule } from './shared-componenets/shared-componenets.module';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OnlyNumberInputDirective } from './shared/directives/only-number-input.directive';
import { LaunchingReportPageComponent } from './launching-report-page/launching-report-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { CampMapLanchingComponent } from './launching-report-page/sharing/camp-map-lanching/camp-map-lanching.component';
import { PolicyComponent } from './auth/policy/policy.component';
import { QAComponent } from './auth/qa/qa.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { FilterModule } from './helper/filter/filter.module';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    LaunchingReportPageComponent,
    HomePageComponent,
    CampMapLanchingComponent,
    PolicyComponent,
    QAComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    MatMenuModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    IonicModule.forRoot(),
    TranslocoRootModule,
    SharedComponenetsModule,
    NgOtpInputModule,
    MatCheckboxModule,
    NgxPermissionsModule.forRoot(),
    MatExpansionModule,
    FilterModule,
    ClipboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  exports: [
    CampMapLanchingComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
