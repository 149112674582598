import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navigate-back',
  templateUrl: './navigate-back.component.html',
  styleUrls: ['./navigate-back.component.scss']
})
export class NavigateBackComponent {

  @Input() label: string;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute
    ) {}

  goBack(): void {
    this.router.navigate(['.'], { relativeTo: this.activeRoute.parent });
  }

}
