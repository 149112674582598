import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ExportToExcelService {
  constructor() {}
// parms1 = data
// parms2 = filename
  exportAsExcelFile(json: any[], excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename =
      `${excelFileName}_` + moment().format('YYYY/MM/DD') + '.xlsx';
    XLSX.writeFile(wb, filename);
  }
}
