import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() displayedColumns: any;
  displayedColumns_show: any
  @Input() dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  @Output() edit = new EventEmitter();



  constructor() { }

  ngOnInit() {
    this.displayedColumns_show = this.displayedColumns.map(d => d.col)
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  edit_modal(data) {
    this.edit.emit(data);
  }


}
