import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {



  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken = localStorage.getItem('token');
    if (accessToken != null) {
      req = req.clone({
        setHeaders: {
          'authorization': `Bearer ${localStorage.getItem('token')}`,
          'Accept': 'application/json'
        }
      });

    } else if (localStorage.getItem('verifyToken')) {

      req = req.clone({
        setHeaders: {
          'authorization': `Bearer ${localStorage.getItem('verifyToken')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
    } else if (localStorage.getItem('resetPasswordToken')) {

      req = req.clone({
        setHeaders: {
          'authorization': `Bearer ${localStorage.getItem('resetPasswordToken')}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
    }
    return next.handle(req);
  }
}
