import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-pie2',
  templateUrl: './pie2.component.html',
  styleUrls: ['./pie2.component.scss'],
})
export class Pie2Component implements AfterViewInit {
  option: EChartsOption = {};
  @Input() chartData: any;
  @Input() title: any;
  constructor() {}
  ngAfterViewInit(): void {
    this.option = {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
        selectedMode: false,
      },
      color: ['#2F58A8', '#F2EB3D', '#C12036', '#F18357', '#86AD3F'],
      series: [
        {
          name: this.title,
          type: 'pie',
          radius: [5, 100],
          center: ['50%', '60%'],
          data: this.chartData,
          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return param.name + ' ' + ` (${param.value})`;
            },
          },
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            itemStyle: {
              borderWidth: 0,

              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
  }

  ngOnInit(): void {}
}
