import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-flag-icon',
  templateUrl: './flag-icon.component.html',
  styleUrls: ['./flag-icon.component.scss']
})
export class FlagIconComponent implements OnInit {

  @Input() color = '#000';

  constructor() { }

  ngOnInit(): void {
  }

}
