import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ImpAuthService } from '../services/imp-auth.service';
import { ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { user_admin } from 'src/app/constent/Route';
import Swal from 'sweetalert2';
import { ImpApiServicesService } from 'src/app/services/imp-api-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  loading = false;

  // role1 = [
  //   "المحافظة على سرية جميع المعلومات التي تم الاطلاع عليها أو سيتم الاطلاع عليها أو استلامها خلال فترة تنفيذ وتشغيل وعمل منصة التفويج 1445. على أن يقتصر استعمال هذه المعلومات على الجهة فقط لإنجاز الأعمال المتفق عليه مع الوزارة ضمن نطاق المشروع. "

  //   ,
  //   "الالتزام بحماية المعلومات وسريتها على حسب تصنيفها، والالتزام بحفظها وتخزينها والتعامل معها بالطريقة التي تمنع كشفها أو استخدامها بشكل غير مسموح. "
  //   ,
  //   "يمنع نشر، أو إعلان، أو تعميم أي معلومات، أو مواد مخلة بالآداب العامة والتعاليم الإسلامية والتي تؤدي إلى انتهاك القوانين. "
  //   ,
  //   "يمنع استخدام أي وسيلة أو برنامج أو إجراء قد يؤدي إلى تعطيل أنظمة المنصة او الحاق الضرر بها. "
  //   ,
  //   "يمنع الوصول غير المصرح به للأنظمة لغير الأشخاص المخولين، وذلك باستخدام كلمة مرور ورقم تعريف شخصي مزيفة أو محاولة انتحال شخصية أو سلطة القيام بأي إجراء نيابة عن شخص آخر. "
  //   ,
  //   `أي محاولة لاختراق شبكات وأنظمة المنصات من أي نوع تعتبر عملاً إجراميا، ويقع تحت نظام " مكافحة الجرائم المعلوماتية "، ويشمل ذلك على سبيل المثال لا الحصر، محاولة فحص الشبكات أو العناوين المنطقية (IP) أو عمليات الاستطلاع على الشبكة أو محاولة معرفة تفاصيل عن الأنظمة المستخدمة وغير ذلك. ويستثنى من ذلك الأشخاص المخولين بشكل مباشر من قبل الوزارة للقيام بتلك`
  //   ,
  //   `يمنع إرسال أو تخزين كافة أشكال البيانات المخالفة للقوانين مثل: المعلومات الشخصية والمواد الخاضعة لحقوق الملكية، أو استخدام البرمجيات غير المرخصة وغيرها. `
  //   ,
  //   `يجب على جميع الموظفين الحرص على حماية الأصول والممتلكات والمحافظة عليها من السرقة والتخريب والهدر وكل ما من شأنه إتلاف هذه الأصول. `

  //   ,
  //   `استغلال الأصول التقنية على النحو الأمثل لتحقيق أهداف العمل التي وجدت من أجلها والكشف فوراً عن سرقة أو فقدان أو  الاستخدام غير المصرح به. `
  //   ,
  //   `يجب على المستخدمين تسجيل الخروج من أجهزة الحاسب أو وضعها على وضع الإقفال (وضع التأمين) عند مغادرة المكتب ولو كان ذلك لفترة زمنية قصيرة. `
  //   ,
  //   `مخالفة هذه الشروط والسياسات قد يعرض المستخدم للمساءلة القانونية إتباعاً لنظام الوزارة ونظام مكافحة الجرائم المعلوماتية. `,
  //   `يمنع التعديل على أي أصل تقني بالإضافة أو الإزالة من مكوناته إلا بعد موافقة مدير المشروع للمنصة 1445. `,
  //   `إن التعهد ساري طول مدة العمل ويظل مستمر بعد إنهاء /انتهاء العلاقة الوظيفية مع الجهة. ويحق لوزارة الحج والعمرة بأنه في حال الإخلال بهذا التعهد أو الكشف غير المشروع للمعلومات السرية مما قد يتسبب في إلحاق أضرار مادية أو معنوية للوزارة فإنه يحق للوزارة مقاضاة الجهة أو الموظف قانونيا ورفع دعوى التعويض وغيرها من الدعاوى الأخرى، والمطالبة بتطبيق وتنفيذ العقوبات الجزائية والإدارية والمدنية عليه أمام الجهات والسلطات التنفيذية والقضائية السعودية أو الأجنبية. `,
  //   `الالتزام والامتثال بكافة السياسات والتشريعات المعمول بها في الوزارة منها ( نظام حماية البيانات الشخصية، سياسات وتشريعات الامن السيبراني، سياسات ومتطلبات مكتب إدارة البيانات الوطنية وسياسة الخصوصية و الاستخدام). `
  // ]

  // role2 = [
  //   `إتباع سياسات وزارة الحج والعمرة الخاصة بمنصة التفويج.. `
  //   ,
  //   `الالتزام بسياسة استخدام الأصول التقنية. `
  //   ,
  //   `الالتزام بسياسات ومتطلبات ادارة البيانات وحوكمتها. `
  //   ,
  //   `الاطلاع على نظام مكافحة الجرائم المعلوماتية والعقوبات المترتبة على المخالفات والتقييد بما فيها. `,
  //   `التواصل مع إدارة أمن المعلومات في حال وجود أي استفسار أو عن طريق البريد الالكتروني InfoSec@haj.gov.sa. `
  //   ,
  //   `سيتم تحديث سياسات الخصوصية وحماية البيانات بشكل دوري عند الحاجة ومن ثم نشرها في منصة التفويج، فلذلك يجب الاطلاع المستمر على التحديثات التي قد تطرأ على السياسات ومن ثم الالتزام بها.`
  //   ,
  //   `سيتم ايقاف الحساب عند نهاية العمل في حال انتهاء العمل على المنصة او في حال عدم استخدام الحساب لمدة تزيد عن شهر. `
  //   ,
  //   `الالتزام بسياسات الخصوصية المقررة، والتي تشمل حماية البيانات الشخصية وضمان استخدامها بشكل يتوافق مع الغرض المحدد لها. `,
  //   `التقيد بأدوار وصلاحيات المستخدمين المحددة ضمن منصة التفويج بما يضمن التحكم الأمني والفعال في الوصول إلى البيانات.  `
  // ]

  role1 = [
    {
      label: 'سرية المعلومات', list: [
        'الالتزام بالحفاظ على سرية جميع المعلومات التي يتم الاطلاع عليها أو استلامها خلال فترة تنفيذ وتشغيل وعمل منصة التفويج 1445. ',
        'يقتصر استعمال هذه المعلومات على الجهة فقط لإنجاز الأعمال المتفق عليها مع الوزارة ضمن نطاق المشروع. '
      ]
    },

    {
      label: 'حماية المعلومات', list: [
        'الالتزام بحماية المعلومات وسريتها وفق تصنيفها.',
        'حفظ وتخزين المعلومات والتعامل معها بطريقة تمنع كشفها أو استخدامها بشكل غير مسموح.'
      ]
    },
    {
      label: 'المحتوى المخالف', list: [
        'يمنع نشر أو إعلان أو تعميم أي معلومات أو مواد مخالفة للآداب العامة والتعاليم الإسلامية والتي تؤدي إلى انتهاك القوانين.',
      ]
    }
  ]

  role2 = [
    {
      label: 'سلامة الأنظمة', list: [
        'يمنع استخدام أي وسيلة أو برنامج أو إجراء قد يؤدي إلى تعطيل أنظمة المنصة أو إلحاق الضرر بها.'
      ]
    },

    {
      label: 'الوصول غير المصرح به', list: [
        'يمنع الوصول غير المصرح به للأنظمة، ويشمل ذلك استخدام كلمة مرور أو رقم تعريف شخصي مزيف، أو محاولة انتحال شخصية أو سلطة القيام بأي إجراء نيابة عن شخص آخر. '
      ]
    },
    {
      label: 'محاولات الاختراق', list: [
        'أي محاولة لاختراق شبكات وأنظمة المنصات تعتبر عملاً إجرامياً وتقع تحت نظام "مكافحة الجرائم المعلوماتية".',
        'يشمل ذلك محاولة فحص الشبكات أو العناوين المنطقية (IP) أو عمليات الاستطلاع على الشبكة أو محاولة معرفة تفاصيل عن الأنظمة المستخدمة.',
        'يستثنى من ذلك الأشخاص المخولون بشكل مباشر من قبل الوزارة للقيام بتلك الأعمال. ',
      ]
    },
    {
      label: 'البيانات المخالفة للقوانين', list: [
        'يمنع إرسال أو تخزين كافة أشكال البيانات المخالفة للقوانين مثل المعلومات الشخصية والمواد الخاضعة لحقوق الملكية أو استخدام البرمجيات غير المرخصة.'
      ]
    },
  ]
  role3 = [
    {
      label: 'حماية الأصول', list: [
        'يجب على جميع الموظفين الحرص على حماية الأصول والممتلكات والمحافظة عليها من السرقة والتخريب والهدر وكل ما من شأنه إتلاف هذه الأصول.',
        'استغلال الأصول التقنية على النحو الأمثل لتحقيق أهداف العمل، والإبلاغ فوراً عن أي سرقة أو فقدان أو استخدام غير مصرح به.'
      ]
    },

    {
      label: 'استخدام الأجهزة', list: [
        'يجب على المستخدمين تسجيل الخروج من أجهزة الحاسب أو وضعها على وضع التأمين عند مغادرة المكتب ولو لفترة زمنية قصيرة.'
      ]
    },
    {
      label: 'التعديل على الأصول التقنية', list: [
        'يمنع التعديل على أي أصل تقني بالإضافة أو الإزالة من مكوناته إلا بعد موافقة مدير المشروع للمنصة 1445'
      ]
    },
    {
      label: 'البيانات المخالفة للقوانين', list: [
        'يمنع إرسال أو تخزين كافة أشكال البيانات المخالفة للقوانين مثل المعلومات الشخصية والمواد الخاضعة لحقوق الملكية أو استخدام البرمجيات غير المرخصة.'
      ]
    },
  ]

  role4 = [
    {
      label: 'المساءلة القانونية', list: [
        'مخالفة هذه الشروط والسياسات قد تعرض المستخدم للمساءلة القانونية وفقاً لنظام الوزارة ونظام مكافحة الجرائم المعلوماتية.',
        'التعهد ساري طوال مدة العمل ويظل مستمراً بعد انتهاء العلاقة الوظيفية مع الجهة.',
        'يحق لوزارة الحج والعمرة مقاضاة الجهة أو الموظف قانونياً في حال الإخلال بهذا التعهد أو الكشف غير المشروع للمعلومات السرية، والمطالبة بتطبيق العقوبات الجزائية والإدارية والمدنية أمام الجهات والسلطات التنفيذية والقضائية السعودية أو الأجنبية.'
      ]
    },

  ]

  role5 = [
    {
      label: 'سياسات الوزارة', list: [
        'الالتزام والامتثال بكافة السياسات والتشريعات المعمول بها في الوزارة، منها نظام حماية البيانات الشخصية، سياسات وتشريعات الأمن السيبراني، سياسات ومتطلبات مكتب إدارة البيانات الوطنية، وسياسة الخصوصية والاستخدام.'
      ]
    },
    {
      label: 'سياسات أمن المعلومات', list: [
        'اتباع سياسات وإجراءات أمن المعلومات لمنصة التفويج، وسياسات وزارة الحج والعمرة الخاصة بالمنصة.'
      ]
    },
    {
      label: 'استخدام الأصول التقنية', list: [
        'الالتزام بسياسة استخدام الأصول التقنية.'
      ]
    },
    {
      label: 'إدارة البيانات', list: [
        'الالتزام بسياسات ومتطلبات إدارة البيانات وحوكمتها'
      ]
    },
    {
      label: 'نظام مكافحة الجرائم المعلوماتية', list: [
        'الاطلاع على نظام مكافحة الجرائم المعلوماتية والعقوبات المترتبة على المخالفات والتقييد بما فيها.'
      ]
    },
    {
      label: 'التواصل مع إدارة أمن المعلومات', list: [
        'التواصل مع إدارة أمن المعلومات: .........'
      ]
    },
    {
      label: 'تحديث السياسات', list: [
        'سيتم تحديث سياسات الخصوصية وحماية البيانات بشكل دوري عند الحاجة، ويجب الاطلاع المستمر على التحديثات والالتزام بها.'
      ]
    },
    {
      label: 'إيقاف الحسابات', list: [
        'سيتم إيقاف الحساب عند نهاية العمل على المنصة أو في حال عدم استخدام الحساب لمدة تزيد عن شهر.'
      ]
    },
    {
      label: 'سياسات الخصوصية', list: ['الالتزام بسياسات الخصوصية المقررة، والتي تشمل حماية البيانات الشخصية وضمان استخدامها بشكل يتوافق مع الغرض المحدد لها. ']
    },
    {
      label: 'أدوار وصلاحيات المستخدمين', list: ['التقيد بأدوار وصلاحيات المستخدمين المحددة ضمن منصة التفويج بما يضمن التحكم الأمني الفعال في الوصول إلى البيانات.']
    },
  ]

  fieldTextType: boolean;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  constructor(
    private IimpAuthService: ImpAuthService,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private impApiServicesService: ImpApiServicesService,
    private spinner: NgxSpinnerService,

  ) { }
  @ViewChild('policy') policy: Element;

  login = {
    username: '',
    password: '',
    is_web: true,
    otp: "",
    token: '',
    attempts: localStorage.getItem('otp')
  };
  check_policy = null
  accept_temrms = false
  ngOnInit(): void {
    document.body.setAttribute('data-bs-theme', 'light');
    this.modalService.dismissAll()
    localStorage.removeItem('verifyToken');
    localStorage.removeItem('otp');
  }

  submit(modal) {
    this.loading = true;
    let currentTime = moment();
    if (localStorage.getItem('time') !== null) {
      let checkTime = moment(localStorage.getItem('time'))
      if (currentTime.isSameOrAfter(checkTime)) {
        localStorage.removeItem('time')
        this.spinner.show()
        this.IimpAuthService.login(this.login).subscribe(data => {
          this.spinner.hide()
          this.modalService.open(modal)
          this.toastr.success(data.message)
          localStorage.setItem('verifyToken', data.data.token);

          this.fun_on = false
          this.check_otp = true

          // localStorage.setItem('token', data.data.token);
          // localStorage.setItem('user', JSON.stringify(data.data.user));
          // window.location.reload();


        });
      } else {
        this.toastr.warning('أعد المحاولة بعد دقيقه')
        return null
      }
    } else {
      this.spinner.show()
      this.IimpAuthService.login(this.login).subscribe(data => {
        this.spinner.hide()
        this.modalService.open(modal)
        this.toastr.success(data.message)
        localStorage.setItem('verifyToken', data.data.token);

        this.fun_on = false
        this.check_otp = true

        // localStorage.setItem('token', data.data.token);
        // localStorage.setItem('user', JSON.stringify(data.data.user));
        // window.location.reload();


      });
    }


  }


  reset_password() {
    // this.router.navigate(["reset-password?step=1"])
    this.router.navigate(['reset-password'], { queryParams: { step: '1' } });
  }

  check_otp = true
  fun_on = false
  onOtpChange(otp) {
    this.login.otp = otp;
    if (this.check_otp) {
      this.check_otp = this.login.otp.length === 3 ? false : true
    } else {
      if (this.login.otp.length === 4 && this.fun_on === false) {
        this.fun_on = true
        this.spinner.show()
        setTimeout(() => {
          this.send_verify()
        }, 500);
      }
    }

  }

  data = null;
  send_verify() {



    this.IimpAuthService.verify(this.login.otp).subscribe(data => {
      this.spinner.hide()
      this.data = data.data
      localStorage.setItem('token', data.data.token);
      this.loading = false;
      this.modalService.dismissAll()
      this.check_policy = data.data.user
      if (this.check_policy.accepted_terms == false) {
        this.private_policy();
      } else {
        this.check_policy.accepted_terms = true;
        localStorage.setItem('user', JSON.stringify(this.check_policy));
        window.location.reload();
      }

    })
  }

  private_policy() {
    this.modalService.open(this.policy, {

      centered: true,
      size: 'xl',
      keyboard: false,
      backdrop: 'static',

    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    let top = document.getElementById('policy-container');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  approvePolicy() {
    Swal.fire({
      title: 'تنويه',
      text: `
      هل أنت موافق على الشروط و الأحكام؟
      `,
      icon: 'warning',
      showCancelButton: true,
      heightAuto: false,
      confirmButtonColor: '#ebcd94',
      cancelButtonColor: '#E7E5E4',
      customClass: 'w-80-alert',
      cancelButtonText: 'لا',
      confirmButtonText: 'نعم',
    }).then((result) => {
      if (result.isConfirmed) {
        this.impApiServicesService
          .post(user_admin.policy, null)
          .subscribe((data) => {
            this.check_policy.accepted_terms = true;

            localStorage.setItem('user', JSON.stringify(this.check_policy));
            window.location.reload();
          });
      }
    });
  }

  open_modal(modal) {
    this.submit(modal)
  }

}
