import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { report_schedule } from '../constent/Route';
import { ExportToExcelService } from '../services/export-to-excel.service';
import { ImpApiServicesService } from '../services/imp-api-services.service';
import { report_jamarat } from '../constent/report-jamarat';
import { GenerateMapService } from './generate-map.service';

@Component({
  selector: 'app-launching-report-page',
  templateUrl: './launching-report-page.component.html',
  styleUrls: ['./launching-report-page.component.scss']
})
export class LaunchingReportPageComponent implements OnInit {
  d = new Date();
  date_print = `${this.d.getFullYear()} / ${this.d.getMonth() + 1
    } / ${this.d.getDate()}`;
  time_print = moment().format('LTS'); // 12:59:57 PM


  throwing_1: any
  throwing_2: any
  throwing_3: any
  throwing_4: any
  dataCamp = null
  schedule_types_list = []
  arrayListSch = []
  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private impApiServicesService: ImpApiServicesService,
    private exportToExcelService: ExportToExcelService,
    private router: ActivatedRoute,
    private generateMapService: GenerateMapService,
    private route: Router,

  ) { }

  ngOnInit() {
    let index = 1
    for (let value of report_jamarat) {
      let text = value.company.id + index
      this.arrayListSch.push({
        dataCamp: value,
        throwing_1: value.schedule.filter(d => d.dispatching_day == 9 || d.dispatching_day == 10)
          .sort((a, b) => (a.dispatching_day - b.dispatching_day || a.dispatching_time.localeCompare(b.dispatching_time))),
        throwing_2: value.schedule.filter(d => d.dispatching_day == 11).sort((a, b) => a.dispatching_time.localeCompare(b.dispatching_time)),
        throwing_3: value.schedule.filter(d => d.dispatching_day == 12).sort((a, b) => a.dispatching_time.localeCompare(b.dispatching_time)),
        throwing_4: value.schedule.filter(d => d.dispatching_day == 13).sort((a, b) => a.dispatching_time.localeCompare(b.dispatching_time)),
        index: text
      })


      index++
    }

  }



  map(data, text) {

    this.generateMapService.start_map(data, text)
    this.generateMapService.generate_Camp(data, text)
  }

  goToPage() {
    this.route.navigate(['/apps/report-schedule/list']);

  }

  clickedCall() {
    const data = {
      "virtual_locations": [this.router.snapshot.params.uuid],
      "schedule_type_uuid": this.schedule_types_list.find(d => d.name_en === 'Jamarat').uuid
    }
    // this.impApiServicesService.post(report_schedule.schedule_download_tracker, data).subscribe(data => {
    // });
  }
}
