import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements OnInit, AfterViewInit {
  option: EChartsOption = {};
  @Input() title: any;
  @Input() value: any;
  @Input() parcent: any;
  @Input() color: any;
  ngAfterViewInit(): void {
    const gaugeData = [
      {
        value: this.parcent,
        name: this.title,
        title: {
          offsetCenter: ['0%', '-10%'],
        },
        detail: {
          valueAnimation: true,
          offsetCenter: ['0%', '40%'],
        },
      },
    ];

    this.option = {
      tooltip: {
        show: true,
      },
      series: [
        {
          type: 'gauge',

          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
            clip: false,
            itemStyle: {
              borderWidth: 1,
              color: this.color || 'red',
            },
          },
          axisLine: {
            lineStyle: {
              width: 15,
            },
          },
          splitLine: {
            show: false,
            distance: 0,
            length: 10,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 50,
          },
          data: gaugeData,
          title: {
            fontSize: 25,
            fontFamily: 'Janna LT,Roboto,Arial',
          },
          detail: {
            show: true,
            width: 50,
            height: 14,
            fontSize: 19,
            minMargin: 5,
            color: 'inherit',
            formatter:
              ` الحجوزات : ${this.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ` +
              '\n' +
              ` (${Math.floor(this.parcent)}%)`,
            fontFamily: 'Janna LT,Roboto,Arial',
          },
        },
      ],
    };
  }

  constructor() {}

  ngOnInit(): void {}
}
