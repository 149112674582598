import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {

  @Input() progress: number = 0;
  @Input() color: string = 'orange';
  @Input() size: number = 100;

  get progressTransform(): string {
    const rotation = this.progress / 100 * 360;
    return `rotate(${rotation}deg)`;
  }

}
