import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-centered-spinner',
  templateUrl: './centered-spinner.component.html',
  styleUrls: ['./centered-spinner.component.scss']
})
export class CenteredSpinnerComponent implements OnInit {

  @Input() diameter: number = 50;
  @Input() backgroundHeight: number = 300; //in px

  constructor() { }

  ngOnInit(): void {
  }

}
