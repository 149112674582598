import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() total: number;
  @Input() maxPages: number;
  @Output() pageChanged = new EventEmitter<number>();

  constructor() {}

  onPageChange(page: number) {
    this.pageChanged.emit(page);
  }
}
