import { DATE_PIPE_DEFAULT_TIMEZONE, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    },
    DatePipe,
    { provide: DATE_PIPE_DEFAULT_TIMEZONE, useValue: 'UTC' },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {

  constructor() {}

  // @Input() date;
  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() placeholder: string = 'التاريخ';

  private _value: any = '';

  get value(): string {
    return this._value;
  }

  set value(val: any) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit(): void {
    this.minDate = this.minDate ? moment(this.minDate, 'DD-MM-YYYY') : null;
    this.maxDate = this.maxDate ? moment(this.maxDate, 'DD-MM-YYYY') : null;
  }

  writeValue(value: any): void {
    this._value = value ? moment(value, 'DD-MM-YYYY') : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
