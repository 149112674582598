import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  constructor() { }
  role1 = [
    {
      label: 'سرية المعلومات', list: [
        'الالتزام بالحفاظ على سرية جميع المعلومات التي يتم الاطلاع عليها أو استلامها خلال فترة تنفيذ وتشغيل وعمل منصة التفويج 1445. ',
        'يقتصر استعمال هذه المعلومات على الجهة فقط لإنجاز الأعمال المتفق عليها مع الوزارة ضمن نطاق المشروع. '
      ]
    },

    {
      label: 'حماية المعلومات', list: [
        'الالتزام بحماية المعلومات وسريتها وفق تصنيفها.',
        'حفظ وتخزين المعلومات والتعامل معها بطريقة تمنع كشفها أو استخدامها بشكل غير مسموح.'
      ]
    },
    {
      label: 'المحتوى المخالف', list: [
        'يمنع نشر أو إعلان أو تعميم أي معلومات أو مواد مخالفة للآداب العامة والتعاليم الإسلامية والتي تؤدي إلى انتهاك القوانين.',
      ]
    }
  ]

  role2 = [
    {
      label: 'سلامة الأنظمة', list: [
        'يمنع استخدام أي وسيلة أو برنامج أو إجراء قد يؤدي إلى تعطيل أنظمة المنصة أو إلحاق الضرر بها.'
      ]
    },

    {
      label: 'الوصول غير المصرح به', list: [
        'يمنع الوصول غير المصرح به للأنظمة، ويشمل ذلك استخدام كلمة مرور أو رقم تعريف شخصي مزيف، أو محاولة انتحال شخصية أو سلطة القيام بأي إجراء نيابة عن شخص آخر. '
      ]
    },
    {
      label: 'محاولات الاختراق', list: [
        'أي محاولة لاختراق شبكات وأنظمة المنصات تعتبر عملاً إجرامياً وتقع تحت نظام "مكافحة الجرائم المعلوماتية".',
        'يشمل ذلك محاولة فحص الشبكات أو العناوين المنطقية (IP) أو عمليات الاستطلاع على الشبكة أو محاولة معرفة تفاصيل عن الأنظمة المستخدمة.',
        'يستثنى من ذلك الأشخاص المخولون بشكل مباشر من قبل الوزارة للقيام بتلك الأعمال. ',
      ]
    },
    {
      label: 'البيانات المخالفة للقوانين', list: [
        'يمنع إرسال أو تخزين كافة أشكال البيانات المخالفة للقوانين مثل المعلومات الشخصية والمواد الخاضعة لحقوق الملكية أو استخدام البرمجيات غير المرخصة.'
      ]
    },
  ]
  role3 = [
    {
      label: 'حماية الأصول', list: [
        'يجب على جميع الموظفين الحرص على حماية الأصول والممتلكات والمحافظة عليها من السرقة والتخريب والهدر وكل ما من شأنه إتلاف هذه الأصول.',
        'استغلال الأصول التقنية على النحو الأمثل لتحقيق أهداف العمل، والإبلاغ فوراً عن أي سرقة أو فقدان أو استخدام غير مصرح به.'
      ]
    },

    {
      label: 'استخدام الأجهزة', list: [
        'يجب على المستخدمين تسجيل الخروج من أجهزة الحاسب أو وضعها على وضع التأمين عند مغادرة المكتب ولو لفترة زمنية قصيرة.'
      ]
    },
    {
      label: 'التعديل على الأصول التقنية', list: [
        'يمنع التعديل على أي أصل تقني بالإضافة أو الإزالة من مكوناته إلا بعد موافقة مدير المشروع للمنصة 1445'
      ]
    },
    {
      label: 'البيانات المخالفة للقوانين', list: [
        'يمنع إرسال أو تخزين كافة أشكال البيانات المخالفة للقوانين مثل المعلومات الشخصية والمواد الخاضعة لحقوق الملكية أو استخدام البرمجيات غير المرخصة.'
      ]
    },
  ]

  role4 = [
    {
      label: 'المساءلة القانونية', list: [
        'مخالفة هذه الشروط والسياسات قد تعرض المستخدم للمساءلة القانونية وفقاً لنظام الوزارة ونظام مكافحة الجرائم المعلوماتية.',
        'التعهد ساري طوال مدة العمل ويظل مستمراً بعد انتهاء العلاقة الوظيفية مع الجهة.',
        'يحق لوزارة الحج والعمرة مقاضاة الجهة أو الموظف قانونياً في حال الإخلال بهذا التعهد أو الكشف غير المشروع للمعلومات السرية، والمطالبة بتطبيق العقوبات الجزائية والإدارية والمدنية أمام الجهات والسلطات التنفيذية والقضائية السعودية أو الأجنبية.'
      ]
    },

  ]

  role5 = [
    {
      label: 'سياسات الوزارة', list: [
        'الالتزام والامتثال بكافة السياسات والتشريعات المعمول بها في الوزارة، منها نظام حماية البيانات الشخصية، سياسات وتشريعات الأمن السيبراني، سياسات ومتطلبات مكتب إدارة البيانات الوطنية، وسياسة الخصوصية والاستخدام.'
      ]
    },
    {
      label: 'سياسات أمن المعلومات', list: [
        'اتباع سياسات وإجراءات أمن المعلومات لمنصة التفويج، وسياسات وزارة الحج والعمرة الخاصة بالمنصة.'
      ]
    },
    {
      label: 'استخدام الأصول التقنية', list: [
        'الالتزام بسياسة استخدام الأصول التقنية.'
      ]
    },
    {
      label: 'إدارة البيانات', list: [
        'الالتزام بسياسات ومتطلبات إدارة البيانات وحوكمتها'
      ]
    },
    {
      label: 'نظام مكافحة الجرائم المعلوماتية', list: [
        'الاطلاع على نظام مكافحة الجرائم المعلوماتية والعقوبات المترتبة على المخالفات والتقييد بما فيها.'
      ]
    },
    // {
    //   label: 'التواصل مع إدارة أمن المعلومات', list: [
    //     'التواصل مع إدارة أمن المعلومات: '+
    //      `
    //      <a   href="tel:+966530532883" class="Blondie text-black">
    //         <strong>966530532883+</strong>
    //      </a>
    //      `
    //   ]
    // },
    {
      label: 'تحديث السياسات', list: [
        'سيتم تحديث سياسات الخصوصية وحماية البيانات بشكل دوري عند الحاجة، ويجب الاطلاع المستمر على التحديثات والالتزام بها.'
      ]
    },
    {
      label: 'إيقاف الحسابات', list: [
        'سيتم إيقاف الحساب عند نهاية العمل على المنصة أو في حال عدم استخدام الحساب لمدة تزيد عن شهر.'
      ]
    },
    {
      label: 'سياسات الخصوصية', list: ['الالتزام بسياسات الخصوصية المقررة، والتي تشمل حماية البيانات الشخصية وضمان استخدامها بشكل يتوافق مع الغرض المحدد لها. ']
    },
    {
      label: 'أدوار وصلاحيات المستخدمين', list: ['التقيد بأدوار وصلاحيات المستخدمين المحددة ضمن منصة التفويج بما يضمن التحكم الأمني الفعال في الوصول إلى البيانات.']
    },
  ]

  ngOnInit(): void {
  }

}
