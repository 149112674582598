import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements AfterViewInit {
  @Input() chartData = [];
  @Input() yaxisLabel: any;
  @Input() color: any;
  option: EChartsOption;
  constructor() {}
  ngAfterViewInit(): void {
    this.chartData = this.chartData.map((item) => item.count);

    this.option = {
      position: 'right',
      yAxis: {
        type: 'category',
        data: this.yaxisLabel,
        inverse: true,
      },
      tooltip: {
        trigger: 'item',
        position: 'inside',
      },
      grid: {
        left: '4%',
        right: '4%',
        bottom: '10%',
        top: '10%',
        containLabel: true,
      },

      xAxis: {
        type: 'value',
        inverse: true, // Set this to true for right-to-left bars
      },

      series: [
        {
          data: this.chartData,
          type: 'bar',

          showBackground: true,
          itemStyle: {
            borderRadius: [10, 0, 0, 10],
          },
        },
      ],
    };
  }

  ngOnInit(): void {}
}
