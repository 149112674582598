import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ImpAuthService } from 'src/app/auth/services/imp-auth.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class PilgrimFlowsGuard implements CanActivate {
  constructor(private router: Router, private impAuthService: ImpAuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem("token");
    const permission = JSON.parse(localStorage.getItem("user"))?.components?.find(d => d.code == 'DSHS')?.permissions?.find(d => d.code == 'DSHS:flows_dashboard');
    if (token == null) {
      this.router.navigate(["login"]);
      this.impAuthService.logout().subscribe(data => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ في النظام',
          text: "إنتهاء صلاحية الجلسة",
          // denyButtonColor: '#F14437',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'الخروج من المنصة',
          heightAuto: false

        })

        localStorage.removeItem('user');
        localStorage.removeItem('token')
        this.router.navigate(["/login"]);
      }, error => {
        Swal.fire({
          icon: 'error',
          title: 'خطأ في النظام',
          text: "إنتهاء صلاحية الجلسة",
          // denyButtonColor: '#F14437',
          showDenyButton: true,
          showConfirmButton: false,
          denyButtonText: 'الخروج من المنصة',
          heightAuto: false

        })
        localStorage.removeItem('user');
        localStorage.removeItem('token')
        this.router.navigate(["/login"]);
      })
      return false
    } else if (permission == undefined) {
      localStorage.removeItem('user');
      localStorage.removeItem('token')
      this.router.navigate(["/login"]);
    } else {
      return true
    }
  }

}
