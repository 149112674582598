import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrls: ['./theme-toggle.component.scss']
})
export class ThemeToggleComponent implements OnInit {

  constructor(private themeService: ThemeService) { }

  isDark = this.themeService.darkTheme;

  ngOnInit(): void {
  }

  toggleTheme(event) {
    this.themeService.darkTheme = event.target.checked;
  }

}
