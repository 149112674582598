import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-pie1',
  templateUrl: './pie1.component.html',
  styleUrls: ['./pie1.component.scss'],
})
export class Pie1Component implements AfterViewInit {
  option: EChartsOption = {};
  @Input() chartData: any;
  @Input() title: any;
  @Input() value_name: string;
  ngAfterViewInit(): void {
    const data = this.chartData;
    const name = this.value_name;
    this.option = {
      tooltip: {
        trigger: 'item',

        formatter(param) {
          return `${param.name}<br/> ${name || 'الحجوزات'} : ${
            param.value
          } <br/> النسبة :  ${
            data[param.dataIndex].percentage != undefined
              ? Math.floor(data[param.dataIndex].percentage) + '%'
              : ''
          } `;
        },
      },
      legend: {
        top: '1%',
        left: 'center',
        // doesn't perfectly work with our tricks, disable it
        selectedMode: true,
      },
      series: [
        {
          color: ['#2F58A8', '#F2EB3D', '#C12036', '#F18357', '#86AD3F'],
          name: this.title,
          type: 'pie',
          radius: ['40%', '60%'],
          center: ['50%', '50%'],
          // adjust the start angle
          itemStyle: {
            borderRadius: 5,
            borderColor: '#fff',
            borderWidth: 2,
          },
          startAngle: 180,

          data: data,
          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return (
                param.name +
                ' \n' +
                ` ${name || 'الحجوزات'} : (${param.value})` +
                '\n' +
                ` النسبة : ${
                  data[param.dataIndex].percentage != undefined
                    ? Math.floor(data[param.dataIndex].percentage) + '%'
                    : ''
                } `
              );
            },
          },
        },
      ],
    };
  }
}
