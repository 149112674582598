import { map } from 'rxjs';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-pie3',
  templateUrl: './pie3.component.html',
  styleUrls: ['./pie3.component.scss'],
})
export class Pie3Component implements AfterViewInit {
  option: EChartsOption = {};
  @Input() chartData: any;
  @Input() title: any;

  hideChart = 0;

  ngAfterViewInit(): void {
    this.option = {
      legend: {
        top: 'top',
      },

      series: [
        {
          name: this.title,
          type: 'pie',
          radius: [50, 120],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8,
          },
          data: [
            { value: 40, name: 'rose 1' },
            { value: 38, name: 'rose 2' },
            { value: 32, name: 'rose 3' },
            { value: 30, name: 'rose 4' },
            { value: 28, name: 'rose 5' },
            { value: 26, name: 'rose 6' },
            { value: 22, name: 'rose 7' },
            { value: 18, name: 'rose 8' },
          ],
        },
      ],
    };
  }

  ngOnInit(): void {}
}
