import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/constent/Route';
import { ImpApiServicesService } from 'src/app/services/imp-api-services.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private impApiServicesService: ImpApiServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,


  ) { }

  steps = null
  phone = ''
  token = null
  ngOnInit() {
    this.route.queryParamMap.subscribe(data => {
      let step = data.get('step')
      this.steps = Number(step)
      this.token = data.get('token')
      if (this.token == null && this.steps == 3) {
        this.steps = 5
      }

    });

  }


  check_otp = true
  fun_on = false
  login = {
    password: '',
    is_web: true,
    otp: "",
    token: '',
    phone: null,
    attempts: null
  };
  onOtpChange(otp) {
    this.login.otp = otp;
    if (this.check_otp) {
      this.check_otp = this.login.otp.length === 3 ? false : true
    } else {
      if (this.login.otp.length === 4 && this.fun_on === false) {
        this.fun_on = true
        this.spinner.show()
        setTimeout(() => {
          this.change_steps(4, null)
        }, 500);
      }
    }

  }
  new_password = ""
  change_steps(step, modal) {
    if (step == 2) {
      this.impApiServicesService.post(User.forget_password, { phone: this.phone }).subscribe(data => {
        this.modalService.open(modal)
        this.login.token = data.data.token
        this.login.phone = this.phone
        this.toastr.success(data.message)
      })
    }
    if (step == 4) {
      this.impApiServicesService.reset_password(User.reset_password, this.login, this.login.token).subscribe(data => {
        this.new_password = data.data.temporary_password
        this.spinner.hide()
        this.modalService.dismissAll()
        this.steps = step
        this.toastr.success(data.message)

      }, (error) => {
        this.login.attempts = error.error.data.attempts
      })
    }

  }

  password = {
    password: null,
    password_confirmation: null
  }

  login_page() {
    this.router.navigate(["login"])

  }

  is_copy() {
    this.toastr.success('تم النسخ')
  }
}
