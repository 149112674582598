import { table } from 'console';
import { environment } from '../../environments/environment';
const BASEURL = environment.baseAPIURL;

export const helper = {
  establishment_list: BASEURL + 'establishment-cm',
};

export const User = {
  login: BASEURL + 'auth/login',
  verify: BASEURL + 'auth/verify-otp',
  logout: BASEURL + 'auth/logout',
  forget_password: BASEURL + 'auth/forgot-password',
  reset_password: BASEURL + 'auth/reset-password',
  update: BASEURL + 'set-up/users/profile',
  info_user: BASEURL + 'set-up/users/me',
  user_log: BASEURL + 'set-up/users/login/statistics',
  cms: {
    list: BASEURL + 'set-up/cms',
    create: BASEURL + 'set-up/cms',
    update: BASEURL + 'set-up/cms',
    delete: BASEURL + 'set-up/cms/',
  }
};

export const user_admin = {
  list: BASEURL + 'set-up/users',
  listById: BASEURL + 'set-up/users/',
  offices: BASEURL + 'main-tafweej-portal/general/helpers/list/offices/',
  create: BASEURL + 'set-up/users',
  delete: BASEURL + 'set-up/users/',
  update: BASEURL + 'set-up/users/',
  policy: BASEURL + 'set-up/users/accept-terms',
  upload_file: BASEURL + 'set-up/users/',

  // user access
  list_compnent: BASEURL + 'set-up/components',
  list_role_by_components: BASEURL + 'set-up/roles/',
  list_role_by_permissions: BASEURL + 'set-up/roles/',
  list_compnenet_by_permission: BASEURL + 'set-up/components/',

  // roles
  list_roles: BASEURL + 'set-up/roles',
  create_roles: BASEURL + 'set-up/roles',
  update_roles: BASEURL + 'set-up/roles/',

  // permission
  create_permissions: BASEURL + 'set-up/permissions',

  // component
  create_component: BASEURL + 'set-up/components',
};

export const main_data = {
  primary_roads: {
    list: BASEURL + 'main-data/primary-roads/',
    create: BASEURL + 'main-data/primary-roads',
    listById: BASEURL + 'main-data/primary-roads/',
    update: BASEURL + 'main-data/primary-roads/',
    delete: BASEURL + 'main-data/primary-roads/',
  },
  secondary_roads: {
    list: BASEURL + 'main-data/secondary-roads/',
    create: BASEURL + 'main-data/secondary-roads',
    listById: BASEURL + 'main-data/secondary-roads/',
    update: BASEURL + 'main-data/secondary-roads/',
    delete: BASEURL + 'main-data/secondary-roads/',
  },
  jamarat_levels: {
    list: BASEURL + 'main-data/jamarat-levels/',
    create: BASEURL + 'main-data/jamarat-levels',
    listById: BASEURL + 'main-data/jamarat-levels/',
    update: BASEURL + 'main-data/jamarat-levels/',
    delete: BASEURL + 'main-data/jamarat-levels/',
  },
  platforms: {
    list: BASEURL + 'main-data/platforms/',
    create: BASEURL + 'main-data/platforms',
    listById: BASEURL + 'main-data/platforms/',
    update: BASEURL + 'main-data/platforms/',
    delete: BASEURL + 'main-data/platforms/',
  },
  stations: {
    list: BASEURL + 'main-data/stations/',
    create: BASEURL + 'main-data/stations',
    listById: BASEURL + 'main-data/stations/',
    update: BASEURL + 'main-data/stations/',
    delete: BASEURL + 'main-data/stations/',

    list_residential_areas: BASEURL + 'main-data/residential-areas/',
  },
  residence_area: {
    list: BASEURL + 'main-data/residential-areas/',
    create: BASEURL + 'main-data/residential-areas',
    listById: BASEURL + 'main-data/residential-areas/',
    update: BASEURL + 'main-data/residential-areas/',
    delete: BASEURL + 'main-data/residential-areas/',
  },
  plots: {
    list: BASEURL + 'main-data/plots/',
    create: BASEURL + 'main-data/plots',
    listById: BASEURL + 'main-data/plots/',
    update: BASEURL + 'main-data/plots/',
    delete: BASEURL + 'main-data/plots/',
  },
  plot_categories: {
    list: BASEURL + 'main-data/plot-categories/',
    create: BASEURL + 'main-data/plot-categories',
    listById: BASEURL + 'main-data/plot-categories/',
    update: BASEURL + 'main-data/plot-categories/',
    delete: BASEURL + 'main-data/plot-categories/',
    assignPlots: BASEURL + 'main-data/plot-categories/',
  },
  operation_codes: {
    list: BASEURL + 'main-data/operation-codes/',
    create: BASEURL + 'main-data/operation-codes',
    listById: BASEURL + 'main-data/operation-codes/',
    update: BASEURL + 'main-data/operation-codes/',
    delete: BASEURL + 'main-data/operation-codes/',
  },
  nationalities: {
    list: BASEURL + 'main-data/countries/',
    create: BASEURL + 'main-data/countries',
    listById: BASEURL + 'main-data/countries/',
    update: BASEURL + 'main-data/countries/',
    delete: BASEURL + 'main-data/countries/',
  },
  actual_locations: {
    list: BASEURL + 'main-data/actual-locations',
    create: BASEURL + 'main-data/actual-locations',
    listById: BASEURL + 'main-data/actual-locations/',
    update: BASEURL + 'main-data/actual-locations/',
    delete: BASEURL + 'main-data/actual-locations/',
    path_by_uuid: BASEURL + 'main-data/actual-locations/',
    list_path: BASEURL + 'main-data/path/paths',
    list_plot: BASEURL + 'main-data/plots/',
  },
  related_actual_locations: {
    list: BASEURL + 'main-data/related-actual-locations/',
    create: BASEURL + 'main-data/related-actual-locations',
    listById: BASEURL + 'main-data/related-actual-locations/',
    update: BASEURL + 'main-data/related-actual-locations/',
    delete: BASEURL + 'main-data/related-actual-locations/',
  },
  hajj_offices: {
    list: BASEURL + 'main-data/hajj-offices',
    create: BASEURL + 'main-data/hajj-offices',
    listById: BASEURL + 'main-data/hajj-offices/',
    update: BASEURL + 'main-data/hajj-offices/',
    delete: BASEURL + 'main-data/hajj-offices/',
    assigned_company: BASEURL + 'main-data/hajj-offices/',
  },
  companies: {
    list: BASEURL + 'main-data/companies/',
    create: BASEURL + 'main-data/companies',
    listById: BASEURL + 'main-data/countries/',
    update: BASEURL + 'main-data/countries/',
    delete: BASEURL + 'main-data/countries/',
    centers_assigned_companies_with_virual_location: BASEURL + 'main-data/companies/',
    list_office_by_id_company: BASEURL + 'main-data/companies/',

  },


  company_statistics: {

    list: BASEURL + 'main-data/company-statistics/',
    listById: BASEURL + 'main-data/company-statistics/',

  },

  office_statistics: {

    list: BASEURL + 'main-data/office-statistics/',
    listById: BASEURL + 'main-data/office-statistics/',

  },

  train_movement_statistics: {

    list: BASEURL + 'main-data/train-movement-statistics/',
    listById: BASEURL + 'main-data/train-movement-statistics/',

  },

  batch_leaders_statistics: {

    list: BASEURL + 'main-data/batch-leaders-statistics/',
    listById: BASEURL + 'main-data/batch-leaders-statistics/',

  },
  batches_statistics: {

    list: BASEURL + 'main-data/batches-statistics/',
    listById: BASEURL + 'main-data/batches-statistics/',

  },

  download_schedules_statistics: {

    list: BASEURL + 'main-data/download-schedules-statistics/',
    listById: BASEURL + 'main-data/download-schedules-statistics/',

  },


  offices: {
    list: BASEURL + 'main-data/offices/',
    create: BASEURL + 'main-data/offices',
    listById: BASEURL + 'main-data/offices/',
    update: BASEURL + 'main-data/offices/',
    delete: BASEURL + 'main-data/offices/',
    list_by_id_virtual_locations: BASEURL + 'main-data/offices/',
    check_virtual_location_shareing: BASEURL + 'main-data/virtual-locations?actual_location_id='
  },
  virtual_locations: {
    list: BASEURL + 'main-data/virtual-locations/',
    create: BASEURL + 'main-data/virtual-locations',
    listById: BASEURL + 'main-data/virtual-locations/',
    update: BASEURL + 'main-data/virtual-locations/',
    delete: BASEURL + 'main-data/virtual-locations/',

    actual_locations_list: BASEURL + 'main-data/actual-locations/',
    list_offices: BASEURL + 'main-data/offices/',
  },
  assign_company_to_camps: {
    listCampsHasCompany:
      BASEURL + 'main-data/hajj-offices/relation/actual-locations/',
    listCampsNoCampany:
      BASEURL + 'main-data/hajj-offices/relation/unassigned-actual-locations/',
    listCompany: BASEURL + 'main-data/hajj-offices/relation/companies/',
    assignCompany:
      BASEURL + 'main-data/hajj-offices/relation/virtual-location/',
  },

  //!add by ahmed transportation route
  transportation: {
    list: BASEURL + 'main-data/transportation-modes',
    create: BASEURL + 'main-data/transportations',
    listById: BASEURL + 'main-data/transportation/',
    update: BASEURL + 'main-data/transportations/',
    delete: BASEURL + 'main-data/transportations/',
  },
  sub_transportation: {
    list: BASEURL + 'main-data/sub-transportation-modes',
    create: BASEURL + 'main-data/transportation',
    listById: BASEURL + 'main-data/transportation/',
    update: BASEURL + 'main-data/transportation/',
    delete: BASEURL + 'main-data/transportation/',
  },
  transportation_mode: {
    list: BASEURL + 'main-data/transportation-modes',
    create: BASEURL + 'main-data/transportation-modes',
    listById: BASEURL + 'main-data/transportation-modes/',
    update: BASEURL + 'main-data/transportation-modes/',
    delete: BASEURL + 'main-data/transportation-modes/',
  },
  sub_transportation_mode: {
    list: BASEURL + 'main-data/sub-transportation-modes',
    create: BASEURL + 'main-data/sub-transportation-modes',
    listById: BASEURL + 'main-data/sub-transportation-modes/',
    update: BASEURL + 'main-data/sub-transportation-modes/',
    delete: BASEURL + 'main-data/sub-transportation-modes/',
  },
  //!add by ahmed shapeables route
  shapeables: {
    list: BASEURL + 'main-data/shapeables',
    create: BASEURL + 'main-data/shapeables',
    listById: BASEURL + 'main-data/shapeables/',
    update: BASEURL + 'main-data/shapeables/',
    delete: BASEURL + 'main-data/shapeables/',
  },
  batches: {
    list: BASEURL + 'main-data/batches',
    create: BASEURL + 'main-data/batches',
    listById: BASEURL + 'main-data/batches/',
    update: BASEURL + 'scheduling/batches/',
    delete: BASEURL + 'scheduling/batches/',
    generateMissiningBatches: BASEURL + 'scheduling/batches/virtual-locations/generate-batches',
    delete_virual_location: BASEURL + 'scheduling/batches/virtual-location/',
    delete_hajj_office: BASEURL + 'scheduling/batches/hajj-offices/',
    delete_company: BASEURL + 'scheduling/batches/companies/',
  },
  train_movemenet: {
    list: BASEURL + 'scheduling/train-movements',
    create: BASEURL + 'scheduling/train-movements',
    update: BASEURL + 'scheduling/train-movements/',
    delete: BASEURL + 'scheduling/train-movements/',
    listById: BASEURL + 'scheduling/train-movements/',
    assign_stations: BASEURL + 'scheduling/train-movements/',
    unassign_stations: BASEURL + 'scheduling/train-movements/',
  },
  assign_train_movemenet_with_camp: {
    list_camps: BASEURL + 'main-data/actual-locations/actual-locations-with-offices',
    list_train_movements: BASEURL + 'scheduling/train-movements?with_stations=',
    create: BASEURL + 'main-data/virtual-locations/assign-train-movements',
    list: BASEURL + 'main-data/virtual-locations/with-train-movements',
    delete: BASEURL + 'main-data/virtual-locations/unassign-train-movements',

  },

  members: {
    list: BASEURL + 'main-data/members',
    create: BASEURL + 'main-data/members',
    listById: BASEURL + 'main-data/members/',
    update: BASEURL + 'main-data/members/',
    delete: BASEURL + 'main-data/members/',
  },
  report: {
    list: BASEURL + 'main-data/full-view',
  },
  map_images: {
    list: BASEURL + 'main-data/gis-images',
    update: BASEURL + 'main-data/gis-images',
  },
};

export const paths_management = {
  type_paths: {
    list: BASEURL + 'main-data/path/path-types',
    create: BASEURL + 'main-data/path/path-types',
    listById: BASEURL + 'main-data/path/path-types/',
    update: BASEURL + 'main-data/path/path-types/',
    delete: BASEURL + 'main-data/path/path-types/',
  },
  paths: {
    list: BASEURL + 'main-data/path/paths',
    create: BASEURL + 'main-data/path/paths',
    listById: BASEURL + 'main-data/path/paths/',
    update: BASEURL + 'main-data/path/paths/',
    delete: BASEURL + 'main-data/path/paths/',
    paths_by_type_paths: BASEURL + 'main-data/path/path-types/'
  },
  trips: {
    list: BASEURL + 'main-data/path/trips',
    create: BASEURL + 'main-data/path/trips',
    listById: BASEURL + 'main-data/path/trips/',
    update: BASEURL + 'main-data/path/trips/',
    delete: BASEURL + 'main-data/path/trips/',
  },
  path_type: {
    list: BASEURL + 'main-data/path/path-types',
    create: BASEURL + 'main-data/path/path-types',
    listById: BASEURL + 'main-data/path/path-types/',
    update: BASEURL + 'main-data/path/path-types/',
    delete: BASEURL + 'main-data/path/path-types/',
  },
  path_trips: {
    update: BASEURL + 'main-data/path/path-trips/',
  },
  assign_path_to_camps: {
    create: BASEURL + 'main-data/path/paths/assign-locations',
    list: BASEURL + 'main-data/path/paths/assign-locations',
    delete: BASEURL + 'main-data/path/paths/unassign-locations',
  },
};

export const tickets = {
  ticket: {
    list: BASEURL + 'tickets/tickets',
    listById: BASEURL + 'tickets/tickets/',
    update_status: BASEURL + 'tickets/tickets/',
    send_message_ticket: BASEURL + 'tickets/tickets/',
    create: BASEURL + 'tickets/tickets',
    list_priorities: BASEURL + 'tickets/ticket-priorities',
    list_statuses: BASEURL + 'tickets/tickets/statuses',
    user_assign: BASEURL + 'tickets/users',
    statistics: BASEURL + 'tickets/statistics',
  },
  category: {
    list: BASEURL + 'tickets/ticket-categories/',
    create: BASEURL + 'tickets/ticket-categories',
    listById: BASEURL + 'main-data/path/trips/',
    update: BASEURL + 'tickets/ticket-categories/',
    delete: BASEURL + 'tickets/ticket-categories/',
  },
};

export const plot_reservation = {
  admin_plot_booking: {
    list: BASEURL + 'plot-reservation/admin/plot-reservations',
    create: BASEURL + 'plot-reservation/reservation-items/admin-reserve-actual-location',
    listById: BASEURL + 'plot-reservation/admin/plot-reservations/',
    update: BASEURL + 'plot-reservation/admin/plot-reservations/',
    delete: BASEURL + 'plot-reservation/admin/plot-reservations/',
  },
  dashboard: {
    list: BASEURL + 'plot-reservation/dashboard',
    general_cards:
      BASEURL + 'plot-reservation/dashboard/statistics/general-cards',

    reservation_cards:
      BASEURL + 'plot-reservation/dashboard/statistics/reservations-cards',

    hajj_office_cards:
      BASEURL + 'plot-reservation/dashboard/statistics/hajj-offices-cards',
    hajj_offices_cards_details:
      BASEURL +
      'plot-reservation/dashboard/statistics/hajj-offices-cards-details',
    hajj_office_tables:
      BASEURL +
      'plot-reservation/dashboard/statistics/hajj-offices-interactive-table',
    actul_locations_tables:
      BASEURL +
      'plot-reservation/dashboard/statistics/actual-locations-interactive-table',
    actual_locations:
      BASEURL + 'plot-reservation/dashboard/statistics/actual-locations-chart',
    plots_chart: BASEURL + 'plot-reservation/dashboard/statistics/plots-chart',
    assign_company_progress:
      BASEURL +
      'plot-reservation/dashboard/statistics/assigning-companies-progress',
    assign_company_progress_details:
      BASEURL +
      'plot-reservation/dashboard/statistics/assigning-companies-progress-details',
    assigning_offices_progress:
      BASEURL +
      'plot-reservation/dashboard/statistics/assigning-offices-progress',
    assigning_offices_progress_details:
      BASEURL +
      'plot-reservation/dashboard/statistics/assigning-offices-progress-details',
    assigned_companies:
      BASEURL + 'plot-reservation/dashboard/statistics/assigned-companies',
    assigned_actual_locations_chart:
      BASEURL + 'plot-reservation/dashboard/statistics/assigned-actual-locations-chart',
    assigned_offices:
      BASEURL + 'plot-reservation/dashboard/statistics/assigned-offices',
    assigned_offices_chart:
      BASEURL + 'plot-reservation/dashboard/statistics/assigned-offices-chart',
  },
  plot_booking: {
    plot_list: BASEURL + 'plot-reservation/plots',
    reservation_plots: BASEURL + 'plot-reservation/plots/reservation-plots',
    arafat_list: BASEURL + 'plot-reservation/plots/arafat-actual-locations',
    camp_by_plot: BASEURL + 'plot-reservation/plots/',
    plots_category: BASEURL + 'main-data/plot-categories',
    plots_category_by_uuid: BASEURL + 'main-data/plot-categories/',
    create_order: BASEURL + 'plot-reservation/select-categories',
    item_reservation: BASEURL + 'plot-reservation/reservation-items',
    gate_by_plot: BASEURL + 'plot-reservation/plots/',
    already_reservation:
      BASEURL + 'plot-reservation/reservations/active-reservation',
    active_categories:
      BASEURL + 'plot-reservation/select-categories/active-categories',
    cancel_package:
      BASEURL + 'plot-reservation/select-categories/cancel-selected-category',
    submit_package:
      BASEURL + 'plot-reservation/reservations/submit-reservation',
    expirtion_time:
      BASEURL + 'plot-reservation/select-categories/expiration-time',
    campsFilterPh1:
      BASEURL + 'plot-reservation/select-categories/all-reservation-camps',
    campsFilterPh2:
      BASEURL +
      'plot-reservation/select-categories/all-reservation-camps?filter=',
    campsFilterAdmin:
      BASEURL +
      'plot-reservation/select-categories/all-reservation-camps?all',
    // display reservation
    display_reservations: BASEURL + 'plot-reservation/reservations/',
    display_all_reservations:
      BASEURL + 'plot-reservation/reservations/shapes/all?',
    reserved_Actual_Locations:
      BASEURL + 'plot-reservation/reservations/reserved-actual-locations',
    path_arafat: BASEURL + 'plot-reservation/plots/',
    plots_cards: BASEURL + 'plot-reservation/dashboard/statistics/plots-cards',
    mina_camps:
      BASEURL + 'plot-reservation/plots/all-actual-locations-with-hajj-office',

    company_map: BASEURL + 'plot-reservation/companies-map/actual-locations',
    company_map_cards: BASEURL + 'plot-reservation/companies-map/cards',
    company_map_table: BASEURL + 'plot-reservation/companies-map/table',

    office_map: BASEURL + 'plot-reservation/offices-map/actual-locations',
    office_map_cards: BASEURL + 'plot-reservation/offices-map/cards',
    office_map_table: BASEURL + 'plot-reservation/offices-map/table',

    admin_plots_booking: BASEURL + 'plot-reservation/reservations/hajj-office-reservations-stats',
  },

  reservations_status: {
    list_status: BASEURL + 'plot-reservation/reservations/statuses/all',
    list_reservations: BASEURL + 'plot-reservation/reservations',
    change_status: BASEURL + 'plot-reservation/reservations/',
  },

  reservations_report: {
    list: BASEURL + 'plot-reservation/reservations/reservations-report',
    listById:
      BASEURL +
      'plot-reservation/reservations/hajj-office-reservations-report?hajj_office_id=',
  },
};

export const assign_offices_to_camp = {
  list_office_by_id_company: BASEURL + 'main-data/companies/',
  camp_by_plot: BASEURL + 'plot-reservation/plots/',
  assign_office: BASEURL + 'main-data/virtual-locations/',
};

export const survey = {
  survey: BASEURL + 'survey/surveys/',
  user_surveys: BASEURL + 'survey/surveys/user-surveys/',
  sections: BASEURL + 'survey/survey_sections/',
  question_types: BASEURL + 'survey/question-types/',
  questions: BASEURL + 'survey/questions/',
  answers: BASEURL + 'survey/answers/',
  roles: BASEURL + 'set-up/roles/',
};
export const scheduling = {
  preference_times: {
    list: BASEURL + 'scheduling/preference-times?withPagination=0',
    create: BASEURL + 'scheduling/preference-times',
    update: BASEURL + 'scheduling/preference-times/',
    delete: BASEURL + 'scheduling/preference-times/',
    train_movmenet: BASEURL + 'scheduling/train-movements',
    operation_codes: BASEURL + 'main-data/operation-codes',

  },
  scheduling_test: {
    general_comparison: BASEURL + 'scheduling/validate/general-comparison',
    companies_comparison: BASEURL + 'scheduling/validate/companies-comparison',
    hajjOffice_comparison: BASEURL + 'scheduling/validate/hajj-office-comparison',
    office_comparison: BASEURL + 'scheduling/validate/virtual-locations-comparison',
    batches_days_comparison:
      BASEURL + 'scheduling/validate/batches-days-comparison',
    batches_days_chart:
      BASEURL + 'scheduling/validate/batches-days-chart',
  },
  scheduling_buses: {
    nationalities: {
      list: BASEURL + 'scheduling/bus-scheduling/hajj-offices',
      statistics: BASEURL + 'scheduling/bus-scheduling/hajj-offices/statistics-cards',
    },
  },
  jamarat_preferences: {
    list: BASEURL + 'scheduling/jamarat-preferences?withPagination=0',
    create: BASEURL + 'scheduling/jamarat-preferences',
    hajj_office_for_companies: BASEURL + 'main-data/companies/',
    hajj_office_for_offices: BASEURL + 'main-data/offices/',
    listByHajjOfficeId: BASEURL + 'scheduling/jamarat-preferences/',
  }

};

// !create by ahmed for admin dashboard
export const data_analysis_admin = {
  dashboard: {
    list: BASEURL + 'admin-dashboard/dashboard',
  },
};

export const report_schedule = {
  virtual_location: BASEURL + 'main-data/virtual-locations?withPagination=0',
  jamarat_schedule: BASEURL + 'scheduling/jamarat-schedules/',
  train_schedule: BASEURL + 'scheduling/train-schedules/',
  bus_schedule: BASEURL + 'scheduling/bus-scheduling/virtual-locations/',
  schedule_types: BASEURL + 'scheduling/schedule-types/',
  schedule_download_tracker: BASEURL + 'scheduling/schedule-download-tracker/',
  all_jamarat_schedule: BASEURL + 'scheduling/jamarat-schedules/',
  all_train_schedule: BASEURL + 'scheduling/train-schedules/',
  all_bus_schedule: BASEURL + 'scheduling/bus-scheduling/companies/',

};

export const reshuffle = {
  jamarat: {
    filters: BASEURL + 'reshuffle/filters',
    filtered_data: BASEURL + 'reshuffle/filtered-data',
    graph_data: BASEURL + 'reshuffle/graph-data',
    update: BASEURL + 'reshuffle/update',
  },
  train: {
    filters: BASEURL + 'reshuffle/train-reshuffle/filters',
    filtered_data: BASEURL + 'reshuffle/train-reshuffle/filtered-data',
    graph_data: BASEURL + 'reshuffle/train-reshuffle/graph-data',
    update: BASEURL + 'reshuffle/train-reshuffle/update',
  },
}

export const murshid = {
  list: BASEURL + 'batch-leaders/office-batch-leaders/',
  create: BASEURL + 'batch-leaders/batch-leader-profiles/',
  listById: BASEURL + 'murshid/',
  update: BASEURL + 'batch-leaders/office-batch-leaders/',
  delete: BASEURL + 'batch-leaders/office-batch-leaders/',
  create_batch_leader: BASEURL + 'batch-leaders/office-batch-leaders/',
  batches_list: BASEURL + 'batch-leaders/batch-leaders/',
  batches_assign: BASEURL + 'batch-leaders/batch-leaders/',
  batches_update: BASEURL + 'batch-leaders/batch-leaders/',
  batches_delete: BASEURL + 'batch-leaders/batch-leaders/',
}


export const interactive_maps = {
  map: BASEURL + 'main-data/interactive-maps',
  stats: BASEURL + 'main-data/interactive-maps/stats',
}

export const muraqeb_management = {
  user: BASEURL + 'mobile-manager/users?withPagination=0&role_uuid=',
  delete: BASEURL + 'set-up/users/',
  listByid: BASEURL + 'mobile-manager/users/',
  assign_user: BASEURL + 'mobile-manager/users/',
  zone_management: {
    list: BASEURL + 'mobile-manager/zones/',
    create: BASEURL + 'mobile-manager/zones/',
    listById: BASEURL + 'mobile-manager/zones/',
    update: BASEURL + 'mobile-manager/zones/',
    delete: BASEURL + 'mobile-manager/zones/',
    list_label: BASEURL + 'mobile-manager/actual-locations?withPagination=0',
  },
  inspector_management: {
    list: BASEURL + 'mobile-manager/inspectors/',
    create: BASEURL + 'mobile-manager/inspectors/',
    listById: BASEURL + 'mobile-manager/users/',
    update: BASEURL + 'mobile-manager/inspectors/',
    delete: BASEURL + 'mobile-manager/inspectors/',
    list_zone: BASEURL + 'mobile-manager/users/',
    assign: BASEURL + 'mobile-manager/users/zones/',
    unassign: BASEURL + 'mobile-manager/users/',
  },
}



export const general_reports = {
  allCamps_all_pilegrms: BASEURL + 'main-data/actual-locations/pilgrims-by-type/statistics',
  allCamps_all_pilegrms_by_transport: BASEURL + 'main-data/transportation-modes-statistics',
  allCamps_all_gulf_pilegrms_by_companies: BASEURL + 'main-data/actual-locations/companies-by-type/statistics',
  roads_jamarat_levels_statistics: BASEURL + 'main-data/roads-jamarat-levels-statistics',
  company_details_statistics: BASEURL + 'main-data/company-details-statistics',
  unassigned_company_statistics: BASEURL + 'main-data/unassigned-company-statistics',
  pilgrims_per_transportation_modes_statistics: BASEURL + 'main-data/pilgrims-per-transportation-modes-statistics ',
}
export const general_report_jamarat_preferences = {
  companies: BASEURL + 'scheduling/jamarat-preferences/companies/index',
  companies_delete: BASEURL + 'scheduling/jamarat-preferences/companies/',
  offices: BASEURL + 'scheduling/jamarat-preferences/offices/index',
  offices_delete: BASEURL + 'scheduling/jamarat-preferences/offices/',

}

export const data_brwser = {
  list: BASEURL + 'main-data/data-browser',
  list_table: BASEURL + 'main-data/table-data?table=',
  update: BASEURL + 'main-data/update-data?table=',
}

export const arcgis_data = {
  list: BASEURL + 'main-data/arcgis-data-view',
  create: BASEURL + 'main-data/arcgis-data-view',
  update: BASEURL + 'main-data/arcgis-data-view/',
  delete: BASEURL + 'main-data/arcgis-data-view/',
}

export const interactive_map_api = {
  mina: BASEURL + 'main-data/interactive-maps',
  path: BASEURL + 'main-data/actual-locations/',
  housing: BASEURL + 'scheduling/bus-scheduling/virtual-locations/housing/index'
};

export const muraqeb_dashboard = {
  days: BASEURL + 'data-management/moshref-dashboard/days',
  operations: BASEURL + 'data-management/moshref-dashboard/operations',
  operations_schedule: BASEURL + 'data-management/moshref-dashboard/schedules',
  fouj_by_list: BASEURL + 'data-management/moshref-dashboard/report-summary?',
  cards: BASEURL + 'mobile-manager/dashboard',
  table_cards: BASEURL + 'mobile-manager/zones-statistics',

  muraqeb_data: BASEURL + 'mobile-manager/moraqeb-zones-statistics',
  motabee_data: BASEURL + 'data-management/moshref-dashboard/data',
  moshref_data: BASEURL + 'data-management/moshref-dashboard/data',

  print_report: BASEURL + 'mobile/reports',
}

export const pilgrims_flows = {
  filter: BASEURL + 'dashboard/flows-dashboard/filters?',
  graph: BASEURL + 'dashboard/flows-dashboard/graph-data?',
  jamarat_levels: BASEURL + 'dashboard/flows-dashboard/flows-per-jamarat-levels?',

}

export const plot_preferences = {
  map: BASEURL + 'plot-preference/actual-location-shapes',
  cards: BASEURL + 'plot-preference/cards',
  cart: BASEURL + 'plot-preference/cart',
  cancel: BASEURL + 'plot-preference/cancel',
  confirm: BASEURL + 'plot-preference/submit',
}
