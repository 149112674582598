export const dataFile = {
    myMap: null,
    polygonArray: [],
    polygonArrayAfterAdd: null,
    dataSelected: null,
    formNewBooking: {
        company: "حجاج الداخل",
        camp_name: null,
        quntity: null,
        name: null,
        polygon: null,
        remaining: null
    },
    layerSelected: null,
    showNewPolygonPoint: false,
    isAdd: false,
    filters: {
        items: [
            "establishments",
            "nationalities",
            "offices",
            "train_stations",
            "paths",
            "jamarat_levels",
            "return_streets",
            "access_streets",
            "camp_labels",
            "packages",
        ],
        list: {
            "establishments": null,
            "nationalities": null,
            "offices": null,
            "train_stations": null,
            "paths": null,
            "jamarat_levels": null,
            "return_streets": null,
            "access_streets": null,
            "camp_labels": null,
            "packages": null,
        },
        initData: {
            establishment: "all",
            nationality: "all",
            office: "all",
            train_station: "all",
            access_street: "all",
            return_street: "all",
            path: "all",
            jamarat_level: "all",
            camp_label: "all",
            package: "all",
        },
        data: null,
        checkFilterItemExist: function (list, item) {
            var checkFilterItemExistResult = true
            var check = list.filter(d => d.key == item)
            if (check.length == 0) {
                checkFilterItemExistResult = false
            }
            return checkFilterItemExistResult
        }
    },
    map: {
        tafweejAllCampsData: null,


        streetPromise: null,
        arafatPromise: null,
        minaPromise: null,
        muzPromise: null,
        jamaratLevelPromise: null,
        accessPathPromise: null,
        returnPathPromise: null,
        trainStationsPromise: null,
        trainStationBoundaryPromise: null,
        jamaratLevelJSON: null,
        accessPathJSON: null,
        returnPathJSON: null,

        currentFeature: null,
        control: null,

        // Mashaeer Street
        mashaeerStreetLayer: null,
        streetsJSON: null,


        // Mina Camps
        minaGeoJson: null,
        onEachMInaFeature: null,
        minaCamps: null,

        // Muzdalifah Camps
        muzdalifahCamps: null,
        muzdalifahJSON: null,

        // Arafat Camps
        arafatCamps: null,
        arafatJSON: null,

        // Train Stations Boundary
        trainStationBoundaryLayer: null,
        trainStationBoundaryJSON: null,

        // Train Stations
        trainStationLayer: null,
        trainStationJSON: null,




        accessPaths: null,
        returnPaths: null,
        jamaratLevels: null,


        styleLayer: {
            highlight: {
                weight: 5,
                fillColor: getLayoutColor("mapHightlightColor"),
                color: getLayoutColor("mapHightlightColor"),
                dashArray: "",
                fillOpacity: 0.5,
            },
            roadHighlight: {
                weight: 5,
                fillColor: getLayoutColor("mapRoadHightlightColor"),
                color: getLayoutColor("mapRoadHightlightColor"),
                dashArray: "",
                fillOpacity: 0.5,
            },
        },

        isEmbed: false,
        isDarkMode: false,
        isFirstInit: true,
        currentPathJamaratLevel: null,
        labelArray: [],
        intersectionResult: null,


        selection: {
            isOpen: false,
            // Used when the selection data belongs to default map initialize
            isDefaultSelection: true,
            selected: {},
            current: null,
            data: {
                total_pilgrims: null,
                total_camps: null,
                total_office: null,
            },
            charts: null
        },

        drawnItems: null,
        measureControl: null
    },


    chart: {
        establishmentsChartData: {
            names: [],
            data: []
        },
        nationalitiesChartData: {
            fullData: [],
            names: [],
            data: []
        },
        pathsChartData: {
            fullData: [],
            names: [],
            data: []
        },
        jamaratChartData: {
            fullData: [],
            names: [],
            data: []
        },

        pilgrims_total_count_v2: [],
        Paths_v2: [],
        nationality_For_country: [],
        nationality_For_filter: [],
        nationality_count_v2: [],
        afterNat: [],




        getLayoutColor(item) {
            var layoutColorPalette = {
                /* map */
                measurementColor: {
                    light: '#078794',
                    dark: '#FFF'
                },
                mapHightlightColor: {
                    light: '#078794',
                    dark: '#37cddc'
                },
                mapRoadHightlightColor: {
                    light: '#888',
                    dark: '#29343E'
                },
                mapMultiLineString: {
                    light: '#BBB',
                    dark: '#18202b'
                },
                mapTrainBoundary: {
                    light: '#9fc7a7',//'#8dc298',
                    dark: '#223d16'
                },
                /* Charts */
                chartBarHoverBg: {
                    light: '#EEE',
                    dark: '#344152'
                },
                chartClearTextColor: {
                    light: '#000',
                    dark: '#FFF'
                },
                chartWrapperBackground: {
                    light: '#FFF',
                    dark: '#26303e'
                },
                chartAxisLabelColor: {
                    light: '#74787d',
                    dark: '#d1d9de'
                },
                chartAxisLabelBorderColor: {
                    light: '#CCCDD6',
                    dark: '#344152'
                },
                chartPopoverExtraBackground: {
                    light: '#FFF',
                    dark: '#344152'
                },
                chartPopoverExtraCssText: {
                    light: 'padding: 20px 25px 25px;box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);min-width: 250px;',
                    dark: 'color: #FFF;border: 1px solid #455564;padding: 20px 25px 25px;min-width: 250px;'
                },
                chartFontWeight: {
                    light: 'bold',
                    dark: 'normal'
                }
            };
            return layoutColorPalette[item].light;
        }



    },


}

function getLayoutColor(item) {
    var layoutColorPalette = {
        /* map */
        measurementColor: {
            light: '#078794',
            dark: '#FFF'
        },
        mapHightlightColor: {
            light: '#078794',
            dark: '#37cddc'
        },
        mapRoadHightlightColor: {
            light: '#888',
            dark: '#29343E'
        },
        mapMultiLineString: {
            light: '#BBB',
            dark: '#18202b'
        },
        mapTrainBoundary: {
            light: '#9fc7a7',//'#8dc298',
            dark: '#223d16'
        },
        /* Charts */
        chartBarHoverBg: {
            light: '#EEE',
            dark: '#344152'
        },
        chartClearTextColor: {
            light: '#000',
            dark: '#FFF'
        },
        chartWrapperBackground: {
            light: '#FFF',
            dark: '#26303e'
        },
        chartAxisLabelColor: {
            light: '#74787d',
            dark: '#d1d9de'
        },
        chartAxisLabelBorderColor: {
            light: '#CCCDD6',
            dark: '#344152'
        },
        chartPopoverExtraBackground: {
            light: '#FFF',
            dark: '#344152'
        },
        chartPopoverExtraCssText: {
            light: 'padding: 20px 25px 25px,box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3),min-width: 250px,',
            dark: 'color: #FFF,border: 1px solid #455564,padding: 20px 25px 25px,min-width: 250px,'
        },
        chartFontWeight: {
            light: 'bold',
            dark: 'normal'
        }
    }
    return layoutColorPalette[item].light;
}
