import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-v-bar-chart',
  templateUrl: './v-bar-chart.component.html',
  styleUrls: ['./v-bar-chart.component.scss'],
})
export class VBarChartComponent implements OnInit {
  @Input() chartData = [];
  data = [];
  @Input() yaxisLabel: any;
  color = ['#2F58A8', '#F2EB3D', '#C12036', '#F18357', '#86AD3F', 'gray'];

  option: EChartsOption;
  constructor() { }
  ngOnInit() {
    // this.chartData.forEach((element, index) => {
    //   element.itemStyle = { color: this.color[index] };
    // });
    if (this.chartData !== undefined) {
      let data = this.chartData;


      this.option = {
        tooltip: {
          trigger: 'item',
          formatter(param) {
            return `العدد  المحجوز : ${param.value.toLocaleString()} <br/>  ${data[param.dataIndex].percentage != undefined
              ? 'النسبة : (' + data[param.dataIndex].percentage + '%)'
              : ''
              } `;
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },

        xAxis: {
          type: 'category',
          data: this.yaxisLabel,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            // fontSize: 10,
            // fontWeight: 'bold',
            fontFamily: 'Janna LT',
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            fontSize: 10,
            fontFamily: 'Janna LT',
          },
        },
        series: [
          {
            data: this.chartData,
            type: 'bar',
            barWidth: '30px',
            itemStyle: {
              color: (params) => {
                return this.color[params.dataIndex];
              },
              borderRadius: [5, 5, 0, 0],
            },
            label: {
              show: true,
              fontSize: 16,
              fontWeight: 'bold',
              fontFamily: 'Janna LT',
              position: 'top',

              formatter(param) {
                return `${param.value.toLocaleString()} \n ${data[param.dataIndex].percentage != undefined
                  ? '(' + data[param.dataIndex].percentage + '%)'
                  : ''
                  } `;
              },
            },
          },
        ],
      };
    }
  }
}
